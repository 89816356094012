import * as React from "react";
import { graphql } from "gatsby";
// import * as styles from "../styles/pages/index.module.scss";
import { usePageContext } from "../components/context/PageContext";
import { SEO } from "../components/seo/seo";
import { LinkBlocks } from "../components/blocksContent/LinkBlocks";
import { LegalsQuery } from "../../graphql-types";
import * as styles from "../styles/pages/legals.module.scss";

interface Props {
  data: LegalsQuery;
  location: any;
}

const LegalsPage: React.FC<Props> = ({ data }) => {
  const { sanityLegalsPage } = data;
  const { lang } = usePageContext();

  return (
    <>
      <SEO rawMeta={sanityLegalsPage?._rawMetadata} />
      <div className={styles.wrapper}>
        <header>
          <div className="desktopCentered">
            <h2>{sanityLegalsPage?.data?._rawTitle?.[lang]}</h2>
          </div>
        </header>

        <section>
          <LinkBlocks blocks={sanityLegalsPage?.data?._rawContent?.[lang]} />
        </section>
      </div>
    </>
  );
};

export const QUERY = graphql`
  query Legals {
    sanityLegalsPage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitle
        _rawTitleBreadcrumb
        _rawContent
      }
    }
  }
`;

export default LegalsPage;
